import $ from 'jquery';
import _ from 'lodash';

class ServiceSolution{
  constructor(items){
    this.items = items;
    this.container = $('section.services_item .business-solution');
    this.mobileWidth = 576;
    if(this.container.length === 0){
      return;
    }

    this.Init();
  }


  Init(){
    this.container.on('click', '.solution-item', e => {
      let id = e.currentTarget.id;
      this.SelectItem(id);
      if(this.isMobile){
        // this.ScrollToOffset(this.checkListTopOffset);
        this.ScrollToOffset($(`#${id}`).offset().top-60);
      }
    });
    this.SelectItem(this.firstItemId);
    if(this.isMobile) {
      $('.solution-text-wrapper').hide();
      $('.solution-item').removeClass('active');
    }
  }


  SelectItem(id){
    let item = this.GetItemById(id);
    if ($(`#${id}`).hasClass('active') && this.isMobile){
      $(`#${id}`).removeClass('active');
      $('.solution-text-wrapper').hide();
    } else {
      this.container.find('.solution-item').removeClass('active');
      $(`#${id}`).addClass('active');
      if(this.isMobile) {
        $(`#${id}`).parent().append($('.solution-text-wrapper'));
        $('.solution-text-wrapper').show();
      }
    }
    this.RenderList(item);
  }


  GetItemById(id){
    return _.find(this.items, {id: id});
  }

  get firstItemId(){
    return this.items[0].id;
  }


  get isMobile(){
    return $(window).width() < this.mobileWidth;
  }

  get checkListTopOffset(){
    return $('section.services_item .cost-solution .solution-container').offset().top;
  }

  ScrollToOffset(top){
    $('html, body').animate({ scrollTop: top }, 300);
  }

  RenderList(item){
    let html = '';
    this.container.find('.solution-text-wrapper>div').html('');
    item.advantages.forEach(e => {
      html += '<div class="solution-text-column">';
      html += `<div class="title">${e.title}</div>`;
      html += `<div class="text">${e.text}</div>`;
      html += '</div>';
    });

    this.container.find('.solution-text-wrapper>div').html(html);
  }
}


export default ServiceSolution;