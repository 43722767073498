import Slider from './Slider';
import $ from 'jquery';

class IndexSolutionsSilder extends Slider{
    constructor(container){
        super(container);
        this.breakpoints = {
            default: {
                slides: 6
            },
            md: {
                width: 992,
                slides: 4
            },
            sm:{
                width: 768,
                slides: 3
            },
            xs:{
                width: 450,
                slides: 2
            }
        };
        this.countResponse = $(`${container} .count-text span`)
    }

    Init(){
        super.Init();
        this.SetCountText(1);
        this.targetSlider.on('afterChange', (event, slick) => {
            this.SetCountText(slick.currentSlide + 1)
        })
    }

    SetCountText(count){
        this.countResponse.text(count);
    }

    get settings(){
        return Object.assign(super.settings, {
            slidesToShow: this.breakpoints.default.slides,
            slidesToScroll: 1,
            infinite: true,
            responsive: [
                {
                    breakpoint: this.breakpoints.md.width,
                    settings:{
                        slidesToShow: this.breakpoints.md.slides
                    }
                },
                {
                    breakpoint: this.breakpoints.sm.width,
                    settings:{
                        slidesToShow: this.breakpoints.sm.slides
                    }
                },
                {
                    breakpoint: this.breakpoints.xs.width,
                    settings:{
                        slidesToShow: this.breakpoints.xs.slides
                    }
                }
            ]
        });
    }
}

export default new IndexSolutionsSilder('section.solutions');
