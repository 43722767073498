import $ from 'jquery';
import _ from 'lodash';

class ProjectSelect{
    constructor(data){
        this.container = $(`#${data.idContainer}`);
        if(this.container.length === 0){
            return;
        }
        this.items = data.items;
        this.animateDelay = 300;
        this.projectValue = this.container.find(".project-value");
        this.InitEvents();
        this.SelectItem(this.items[0].id);
        this.timeout = null;
    }

    InitEvents(){
        this.container.on('click', '.project-item .img-wrapprer', (e) => {
            let id = $(e.target).parents('.project-item').get(0).id;
            this.SelectItem(id);
        });
    }

    SelectItem(id){
        let target = $(`#${id}`);
        let item = _.find(this.items, {'id': id});
        if(target.length === 0){
            throw("Element not found");
        }
        if(item == undefined){
            throw("Item project not found");
        }

        this.UnselectAllItem();
        target.find('.img-wrapprer').addClass('active');
        this.RenderContent(item);
    }

    UnselectAllItem(){
        this.container.find('.project-item .img-wrapprer').removeClass("active");
    }

    RenderContent(item){
        this.projectValue.removeClass("show");
        if(this.timeout != null){
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
            this.projectValue.find('h3').html(item.title);
            this.projectValue.find('.project-text').html(item.text);
            this.projectValue.find('.detail-link-page').attr('href', item.url);
            this.projectValue.addClass("show");
        }, this.animateDelay)
    }
}

export default ProjectSelect;