class Validate{
    constructor(data){
        this.data = data;
    }


    get IsValidate(){
        return true;
    }
}


export default Validate;