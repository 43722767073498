import $ from 'jquery';
import PdfProductSpecification from './PdfProductSpecification';
import OffCanvasBg from './OffCanvasBg';

class ProductTabs{
    constructor(pdfUrl){
        this.tabsContainer = $("section.product-detail-page .tabs-cotainer");
        if(this.tabsContainer.length == 0)
            return;
        this.tabsTitles = this.tabsContainer.find(".tabs-title");
        if(this.tabsTitles.length == 0)
            return;
        this.tabsContent = this.tabsContainer.find(".tabs-content");
        this.pdfUrl = pdfUrl;
        this.isPdfLoad = false;
        this.delayHide = 300;
        this.timeoutHide = null;
        this.selectedId = null;
        this.pdfId = 'pdf-tab';
        this.pdfProductSpecification = null;

        this.InitEvents();
        this.SelectTab(this.idFirstTab);
    }


    InitEvents(){
        this.tabsTitles.on('click', e => this.SelectTab($(e.currentTarget).data('target-tab')));
    }


    ShowPopup(id){
        let name = $(`[data-target-tab="${id}"]`).find(".text").text();
        let copyElement = $(`#${id}>div`).clone(true);
        let popup = $(".product-props-popup");
        popup.find(".product-props-popup-wrapper .tabs-cotainer").html("");
        popup.find(".title-wrapper>.name").text(name);

        copyElement.appendTo(popup.find(".product-props-popup-wrapper .tabs-cotainer"));
        this.popupProps.Open();
    }


    SelectTab(id){

        if(this.selectedId == id)
            return;
        this.selectedId = id;
        this.HideContent();
        this.UnSelectAllTitles();
        this.SelectTitle(id);
        if(this.timeoutHide != null)
            clearTimeout(this.timeoutHide);

        this.timeoutHide = setTimeout(() => {
            this.HideAllTabs();
            $(`#${id}`).css('display', 'block');
            this.ShowContent();

            if(id == this.pdfId && !this.isPdfLoad)
                this.LoadPdf();
        }, this.delayHide);
        
    }

    LoadPdf(){
        this.isPdfLoad = true;
        this.pdfProductSpecification = new PdfProductSpecification(this.pdfUrl);
        this.pdfProductSpecification.PdfViewer.EventPdfLoaded = n => {
            this.pdfProductSpecification.OnPdfLoaded(n);
            $(`#${this.pdfId} .tabs-pdf`).addClass('hide-loader');
        };
    }

    HideContent(){
        this.tabsContent.removeClass("show");
    }

    ShowContent(){
        this.tabsContent.addClass("show");
    }

    HideAllTabs(){
        this.tabsContent.find('.tabs-content-item').css('display', 'none');
    }

    UnSelectAllTitles(){
        this.tabsTitles.removeClass("active");
    }

    SelectTitle(id){
        this.tabsContainer.find(`[data-target-tab="${id}"]`).addClass("active");
    }

    get idFirstTab(){
        return this.tabsContent.find('.tabs-content-item').get(0).id;
    }
}

export default ProductTabs;