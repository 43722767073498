import _ from 'lodash';
import Helper from './Helper';
import $ from 'jquery';


class ProductImageNav{
    constructor(itemList, items){
        this.items = items;
        this.itemList = itemList;
        
        this.InitEvents();
    }


    Init(){
        this.items.forEach(e => this.RenderItem(e));
        this.OnRenderList();
    }

    RenderItem(item){
        let id = "img_" + Helper.uniqid;
        item.id_e = id;
        let html = `<div class="img-item" id="${id}">
            <div class="img-wrapper">
                <img src="${item.srcMin}">
            </div>
        <div/>`;
        
        this.itemList.append(html);
    }

    InitEvents(){
        this.itemList.on('click', '.img-item', e => this.OnClickNavElement(e.currentTarget.id))
    }


    UnSelectAll(){
        this.itemList.find(".img-item").each((i, e) => $(e).removeClass('active'));
    }


    SelectItem(id){
        $(`#${id}`).addClass("active");
    }


    //virtual
    OnClickNavElement(id){

    }

    //virtual
    OnRenderList(){

    }
}


export default ProductImageNav;