import $ from 'jquery';

class StatTabs {
    constructor() {
        this.tabsContainer = $('.statistics-tabs');
        if(this.tabsContainer.length === 0) return;
        this.tabsTitles = this.tabsContainer.find('.tab');
        if(this.tabsTitles.length === 0) return;
        this.tabsContent = this.tabsContainer.find('.tab-content');
        this.delayHide = 300;
        this.timeoutHide = null;
        this.selectedId = null;

        this.InitEvents();
        this.SelectTab(this.idFirstTab);
    }

    InitEvents(){
        this.tabsTitles.on('click', e => this.SelectTab($(e.currentTarget).data('target-tab')));
    }

    SelectTab(id){
        if(this.selectedId === id) return;
        this.selectedId = id;
        this.HideContent();
        this.UnSelectAllTitles();
        this.SelectTitle(id);
        if(this.timeoutHide != null) clearTimeout(this.timeoutHide);


        this.timeoutHide = setTimeout(() => {
            this.HideAllTabs();
            $(`#${id}`).addClass('tab-page--visible');
            this.ShowContent();
        }, this.delayHide);
    }

    HideContent(){
        this.tabsContent.removeClass('show');
    }

    ShowContent(){
        this.tabsContent.addClass('show');
    }

    HideAllTabs(){
        this.tabsContent.find('.tab-page').removeClass('tab-page--visible');
    }

    UnSelectAllTitles(){
        this.tabsTitles.removeClass('active');
    }

    SelectTitle(id){
        this.tabsContainer.find(`[data-target-tab="${id}"]`).addClass('active');
    }

    get idFirstTab(){
        return this.tabsContent.find('.tab-page').get(0).id;
    }
}

export default StatTabs;
