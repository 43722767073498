import FormObserver from './FormObserver';

class FormObserverPhoneOrEmail extends FormObserver{
    constructor(...arg){
        super(...arg);
        this.isPhoneEmpty = true;
        this.isEmailEmpty = true;
    }
    

    IsDontError(item, id, type) {
        let isCheck = super.IsDontError(item, id);
        let val = $(`#${id}`).val();
        if(type == "phone"){
            this.isPhoneEmpty = val === "";
        }
        if(type == "email"){
            this.isEmailEmpty = val === "";
        }

        if(this.isPhoneEmpty && this.isEmailEmpty && (type == "phone" || type == "email")){
            return false;
        }
        else if(type == "phone" && !this.isEmailEmpty){
            return true;
        }
        else if(type == "email" && !this.isPhoneEmpty){
            return true;
        }
        else{
            return isCheck;
        }
    }
}

export default FormObserverPhoneOrEmail;