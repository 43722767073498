import $ from 'jquery';

class OffCanvas{
    constructor(containerSelector, containerFixed){
        this.containerSelector = containerSelector;
        this.containerFixed = containerFixed;
        this.tempScroll = 0;
        if(containerFixed == undefined){
            this.containerFixed = ".main-content";
        }
    }

    FixedContent(){
        this.tempScroll = $(window).scrollTop();
        $(this.containerFixed).css("position", "fixed");
        $(this.containerFixed).css("top", -this.tempScroll);
    }

    UnFixedContent(){
        $(this.containerFixed).css("position", "relative");
        $(this.containerFixed).css("top", 0);
        $(window).scrollTop(this.tempScroll);
    }
}

export default OffCanvas;
