import Slider from './Slider';
import $ from 'jquery';
import _ from 'lodash';

class NewsDetailSlider extends Slider{
    constructor(selector, items){
        super(selector);
        this.titleSlide = $(`${selector} .slider-manage .title`);
        this.numberSlide = $(`${selector} .slider-manage .number span`);
        this.items = items;
        this.Init();
    }

    Init(){
        super.Init();
        if(this.targetSlider.length == 0){
            return;
        }
        
        this.targetSlider.on('beforeChange', () => this.BeforeChangeHandler());
        this.targetSlider.on('afterChange', (e, s, currentSliderIndex) => {
            this.AfterChangeHandler(currentSliderIndex)
        });
        this.AfterChangeHandler(0);
    }

    BeforeChangeHandler(){
        this.titleSlide.removeClass('show');
        this.numberSlide.removeClass('show');
    }


    AfterChangeHandler(currentSliderIndex){
        let target = this.targetSlider.find(`[data-slick-index="${currentSliderIndex}"] .slider-item`);
        let id = target.get(0).id;
        let title = _.find(this.items, {id, id}).title;
        
        this.titleSlide.html(title);
        this.numberSlide.html(currentSliderIndex + 1);

        this.titleSlide.addClass('show');
        this.numberSlide.addClass('show');
    }

    get settings(){
        return Object.assign(super.settings, {
            slidesToShow: 1,
            slidesToScroll: 1,
        });
    }
}

export default NewsDetailSlider;