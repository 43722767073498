import $ from 'jquery';

class TextInputObserver{
    constructor(){
        this.selectorInput = ".input-text";
    }


    Init(){
        let self = this;
        $(document).on("input change", `${this.selectorInput} input`, function(){
            self.InputHandler(this);
        });
    }

    InputHandler(target){
        let input = $(target);
        let container = input.parents(this.selectorInput);

        if(container.length === 0){
            throw new Error("Input not in container");
        }

        if(input.val().trim() == ""){
            container.removeClass("show-field-name");
        }
        else{
            container.addClass("show-field-name");
        }
    }
}


export default new TextInputObserver();