import $ from 'jquery';


class FormSelect{
    constructor(container){
        this.container = container;
        this._isOpen = false;
        this.indexSelected = null;
        this.select = this.container.find('select');
    }


    Init(){
        this.RenderList();
        this.InitEvents();
        this.ScrollHide();
    }


    InitEvents(){
        $(window).on('resize', () => this.ScrollHide());
    }


    ScrollHide(){
        let scrollWrapper = this.container.find(".scroll-wrapper");
        scrollWrapper.css("width", "100%");
        let listWidth = this.container.find('.selected-list').outerWidth();
        let scrollWrapperWidth = scrollWrapper.width();
        let scrollWidth = scrollWrapperWidth - listWidth;
        scrollWrapper.css("width", scrollWrapperWidth + scrollWidth);
    }

    Open(){
        this._isOpen = true;
        this.container.addClass("open");
    }

    Close(){
        this._isOpen = false;
        this.container.removeClass("open");
    }

    get IsOpen(){
        return this._isOpen;
    }

    RenderList(){
        let index = this.select.find("option")
            .index($(":selected"));
        this.SelectByIndex(index);
    }

    Equal(elem){
        return elem.get(0) === this.container.get(0);
    }


    ClickHandler(elem){
        let selectedItem = null;
        let selectItem = null;


        if(elem.hasClass('selected-item'))
            selectedItem = elem;
        else if(elem.parents('.selected-item').length !== 0)
            selectedItem = elem.parents(this.selector);

        if(elem.hasClass('select-item'))
            selectItem = elem;
        else if(elem.parents('.select-item').length !== 0)
            selectItem = elem.parents(this.selector);

        if(selectedItem !== null){
            if(this.IsOpen){
                this.Close();
            }
            else{
                this.Open();
            }
        }
        else if(selectItem !== null){
            this.SelectByElement(selectItem);
            this.Close();
        }
    }


    SelectByElement(element){
        
        let html = element.html();
        this.container.find('.select-item').removeClass('active');
        element.addClass('active');
       
        this.indexSelected = this.container
            .find('.select-item')
            .index(element);
        let value = this.select.find('option')
            .eq(this.indexSelected).val();
        this.select.val(value);
        this.container.find('.selected-item .text').html(html);
        this.select.trigger('change');

        //TODO: refactoring
        if(element.hasClass('asc')){
            this.container.find('.selected-item .text').addClass('asc');
        }
        else{
            this.container.find('.selected-item .text').removeClass('asc');
        }
    }


    SelectByIndex(index){
        let element = this.container.find('.select-item').eq(index);
        this.SelectByElement(element);
    }
}


export default FormSelect;