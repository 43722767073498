import OffCanvas from './OffCanvas';
import $ from 'jquery';

class OffCanvasHeaderOffset extends OffCanvas {
    constructor() {
        super();
        this.tabletWidth = 992;
    }


    FixedContent() {
        this.tempScroll = $(window).scrollTop();
        $('body').css('overflow-y','hidden');
    }

    UnFixedContent() {
        super.UnFixedContent();
        // $('header').css('top', 0);
        $('body').removeAttr('style');
    }


    get isTablet() {
        return $(window).width() < this.tabletWidth;
    }


    get topOffet() {
        if (this.isTablet) {
            return 0;
        } else {
            return $('header').height();
        }
    }
}

export default OffCanvasHeaderOffset;
