import ValidateEmpty from './validateForm/ValidateEmpty';
import $ from 'jquery';
import _ from 'lodash';
import Helper from './Helper';
import FormSender from './Form';


class FormObserver{
  constructor(inputs, otherData, type = 'common'){
    this.type = type;
    this.inputs = inputs;
    this.defaultErrorMessage = 'Проверьте введённые данные';
    this.otherData = otherData;
    this.successMessage = '';
  }


  ClearInputs() {
    this.inputs.forEach(e => {
      $(`#${e.id}`).val('');
      $(`#${e.id}`).trigger('input');


    });
    this.validateInputs();

    this.inputs.forEach(e => {
      let label = $(`label[for="${e.id}"]`);

      label.removeClass('error');
      label.find('.check').css('display','none');
    });
  }


  validateInputs() {
    this.inputs.forEach(input => {
      input.validateItems.forEach(item => {
        if(!Helper.isEmpty(item.validateEvent)){
          this.ValidateInput(item, input.id, input.type);
        }
      });
    });
  }


  InitEvents(){

    this.inputs.forEach(input => {
      input.validateItems.forEach(item => {
        if(!Helper.isEmpty(item.validateEvent)){
          $(`#${input.id}`).on(item.validateEvent, () => this.ValidateInput(item, input.id, input.type));
        }
      });
    });
    this.inputs.forEach(input => {
      $(`#${input.id}`).on('input', this.ShowClose )
    })

  }
  ShowClose(e) {
    let $input =  $(e.target);

    if($input.val()) {

     // $input.parents('label').addClass('input-not-empty')
    }else {
      //$input.parents('label').removeClass('input-not-empty')
    }
  }


  ValidateInput(item, id, type){
    let label = $(`label[for="${id}"]`);
    let isValid = this.IsDontError(item, id, type);
    if(isValid){
      if  ($(`#${id}`).val()=='') {
        label.removeClass('error').removeClass('input-valid');
        // label.find('.check').css('display','none');
      } else {
        label.removeClass('error').addClass('input-valid');
        // label.find('.check').css('display', 'block');
      }
      }

    else{
      label.find('.check').css('display','none');
      label.addClass('error');
      if(Helper.isEmpty(item.errorMessage)){
        label.find('.error-text').text(this.defaultErrorMessage);
      }
      else{
        label.find('.error-text').text(item.errorMessage);
      }
    }

    return isValid;
  }


  IsDontError(item, id, type){
    let val = $(`#${id}`).val();
    return new item.validate(val).IsValidate;
  }


  GetInputById(id){
    return _.find(this.inputs, {id: id});
  }


  SendData(){
    let data = {};
    let allValidate = true;
    let self = this;

    this.inputs.forEach(input => {
      input.validateItems.forEach(item => {
        let isValidate = !Helper.isEmpty(item.validate)
                    && this.ValidateInput(item, input.id, input.type);

        allValidate = allValidate && isValidate;
      });
    });

    if(allValidate){
      Object.assign(data, this.GetDataInputs(), this.GetOtherData());

      new FormSender(data, (response) => {
        if(response.errors)
          this.Error(response.errors);
        else
          this.successMessage = response.bitlead;
          this.Success();
      }).SendData();
    }
  }

  GetDataInputs(){
    let data = {
      origin: []
    };
    this.inputs.forEach(input => {

      data.origin.push({
        id: input.id,
        value: $(`#${input.id}`).val(),
        name: input.name,
        type: input.type
      });
    });

    return data;
  }

  Success(){
    console.log('succes');
  }

  Error(errors){
    errors.forEach(element => {
      let label = $(`label[for="${element.id}"]`);
      label.addClass('error');
      label.find('.error-text').text(element.message);
    });
    console.log('error');
  }

  GetOtherData(){
    return {
      other: this.otherData
    };
  }
}


export default FormObserver; 