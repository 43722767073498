import $ from 'jquery';

class SolutionForm{
    constructor(){
        this.allCheckBoxSelector = ".all-checkbox-wrapper .solution-checkbox";
        this.singleCheckBoxSelector = ".sigle-checkbox-wrapper .solution-checkbox";
        this.allCheckBox = null;
        this.singleCheckBox = null;
        this.countCheckBox = 0;
    }


    Init(){
        this.allCheckBox = $(this.allCheckBoxSelector);
        this.singleCheckBox = $(this.singleCheckBoxSelector);
        
        this.countCheckBox = this.singleCheckBox.length;

        this.allCheckBox.on("click", () => this.ToggleAllCheckBox());
        this.singleCheckBox.on("click", () => this.CheckItems());
        
        this.CheckItems();
    }

    CheckItems(){
        let items = this.singleCheckBox.find("input:checked");

        if(items.length === this.countCheckBox - 1){
            this.allCheckBox.find("input").prop('checked', false);
        }
        else if(items.length === this.countCheckBox){
            this.allCheckBox.find("input").prop('checked', true);
        }

        this.singleCheckBox.addClass("inactive");
        items.parents(".solution-checkbox").removeClass("inactive");
    }


    ToggleAllCheckBox(){
        let isChecked = this.allCheckBox.find("input").is(':checked');
        this.singleCheckBox.find("input").prop('checked', isChecked);
        this.CheckItems();
    }
}


export default new SolutionForm();