import Validate from './Validate';


class ValidateEmail extends Validate{
    constructor(data){
        super(data);
        this.pattern = /.+@.+\..+/ig;
    }

    get IsValidate(){
        return this.data.match(this.pattern) !== null && this.data.length > 0;
    }
}

export default ValidateEmail; 