import FormObserver from './FormObserver';
import OffCanvasBg from './OffCanvasBg';
import ValidatePhone from './validateForm/ValidatePhone';
import Validate from './validateForm/Validate';
import ValidateEmpty from './validateForm/ValidateEmpty';
import ValidateEmail from './validateForm/ValidateEmail';
import $ from 'jquery';
import FormSuccessPopup from './FormSuccessPopup';
import FormObserverPhoneOrEmail from './FormObserverPhoneOrEmail';

class FormPopup {
  constructor() {
    this.popupSelector = '.callback-popup';
    this.container = $('.off-canvas-bg.callback-popup');
    this.formType = 'common';
    this.formObserver = null;
  }

  Init() {
    if($(this.popupSelector).length === 0 ){
      return;
    }

    this.popup = new OffCanvasBg(this.popupSelector);
    // this.formObserver = new FormObserver(this.inputs, this.otherData, this.formType);
    this.formObserver = new FormObserverPhoneOrEmail(this.inputs, this.otherData, this.formType);
    this.formObserver.ClearInputs();
    this.formObserver.InitEvents();
    this.formObserver.Success = () => {
      this.successHandler();
    };
    this.selectorsShowPopup.forEach(e => {
      $(e).on('click', () => this.popup.Open());
    });

    this.popup.CloseEvent = () => {
      this.container.find('.submit').off('click', () => this.formObserver.SendData());
    };
    this.container.find('.close-icon').on('click', () => this.popup.Close());
    this.container.find('.submit').on('click', () => this.formObserver.SendData());
    this.container.find('.clear').on('click', () => {
      this.formObserver.ClearInputs();
    });

  }

  get inputs() {
    return [
      {
        id: 'name_popup',
        name: 'Имя',
        type: 'name',
        validateItems: [
          {
            validate: Validate,
            validateEvent: 'change',
            errorMessage: 'Проверьте введённые данные',
          }
        ]
      },
      {
        id: 'phone_popup',
        name: 'Телефон',
        type: 'phone',
        validateItems: [
          {
            validate: ValidatePhone,
            validateEvent: 'change',
            errorMessage: 'Проверьте номер телефона',
          }
        ]
      },
      {
        id: 'comment_popup',
        name: 'Имя',
        type: 'comment',
        validateItems: [
          {
            validateEvent: 'change',
            validate: Validate,
          }
        ]
      },
      // {
      //   id: 'email_popup',
      //   name: 'Почта',
      //   type: 'email',
      //   validateItems: [
      //     {
      //       validate: ValidateEmail,
      //       errorMessage: 'Проверьте почту',
      //       validateEvent: 'change',
      //     }
      //   ]
      // },
    ];
  }

  get selectorsShowPopup() {
    return ['.open-callback-popup'];
  }

  get otherData() {
    return [
      { id: 'url', value: this.currentUrl, name: 'Url', type: 'url' }
    ];
  }

  get currentUrl() {
    return window.location.origin + window.location.pathname;
  }

  successHandler(){
    this.popup.Close();
    this.formObserver.ClearInputs();
    let successForm = new FormSuccessPopup();
    successForm.Init();

    if (this.formObserver.successMessage) {
      $(successForm.popupSelector).find('.message-subtitle').after('<div class="message-subtitle success">' + this.formObserver.successMessage + '</div>');
    }

    successForm.Open();
    $(document).trigger(`form.success.${this.formType}`);
  }
}


export default FormPopup;
