import $ from 'jquery';
import GeographySvg from './GeographySvg';

class GeographySelector{
    constructor(items){
        this.containerSelector = ".geography";
        this.svgSelector = ".geography .column-map svg";
        this.container = null;
        this.svgManage = null;
        this.selectorList = null;
        this.selectedItem = null;
        this.selectedId = null;
        this.items = items;

        this.Init();
    }

    Init(){
        this.container = $(this.containerSelector);
        if(this.container.length === 0){
            return;
        }
        this.selectorList = this.container.find(".selector-list");
        this.selectedItem = this.container.find(".selected-item");
        this.svgManage = new GeographySvg(this.items, this.svgSelector);
        
        this.container.on("click", ".selector-item", e => {
            let id = e.currentTarget.id;
            this.ClickHandler(id);
            this.SelectMobItem(id);
        });

        $(document).on('click', e => {
            let target = $(e.target);
            if(target.parents(".selected-item").length != 0){
                target = target.parents(".selected-item");
            }
            
            if(target.hasClass('selected-item') && !target.hasClass('open')){
                this.OpenSelectorMob();
            }
            else{
                this.HideSelectorMob();
            }
        });

        this.ClickHandler(this.items[0].id);
    }

    ClickHandler(id){
        this.container.find(".selector-item").removeClass("active");
        this.svgManage.SelectPath(id);
        this.container.find(`#${id}`).addClass("active");
        
    }

    OpenSelectorMob(){
        this.selectorList.addClass("open");
    }

    HideSelectorMob(){
        this.selectorList.removeClass("open");
    }

    SelectMobItem(id){
        let name = $(`#${id}`).find(".name").text();
        let loc = $(`#${id}`).find(".location").text();
        this.selectedItem.find(".name").text(name);
        this.selectedItem.find(".location").text(loc);
    }
}

export default GeographySelector;