import YouTubePlayer from 'youtube-player';
import $ from 'jquery';

export default class{
    constructor(){
        this.idContainer = 'about-video';
        this.videoId = null;
        this.player = null;
    }

    Init(){
        if(document.getElementById(this.idContainer) !== null){
            $('section.about-page .column-video .play-btn').on('click', () => {
                this.videoId = $(`#${this.idContainer}`).data('video-id');
                this.RemoveTags();
                this.StartVideo();
            });
        }
    }

    StartVideo(){
        this.player = YouTubePlayer(this.idContainer, this.settingsPlayer);
        this.player.loadVideoById(this.videoId);
        this.player.playVideo();
    }

    RemoveTags(){
        $('section.about-page .column-video .play-btn').remove();
        $('section.about-page .column-video .img-wrapper').remove();
    }

    get settingsPlayer(){
        return {
            width: '100%',
            height: '100%'
        };
    }
}