import $ from 'jquery';
import Inputmask from 'inputmask';

class PhoneMask{
  constructor(){

  }


  Init(){
    this.selectors.forEach(e => this.inputmask.mask($(e).get(0)));
  }


  get inputmask(){
    return new Inputmask({'mask': '+7 (999) 999-9999'});
  }

  get selectors(){
    return [
      '#phone_popup',
      '#phone_footer',
      '#phone_product_popup',
      '#phone_service_popup'
    ];
  }
}


export default new PhoneMask();
