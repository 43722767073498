import $ from 'jquery';
import {default as CountUp} from 'countup.js';

class IndexAdvantagesNumberAnimate{
    constructor(selector){
        this.selectorContainer = selector;
        this.container = null;
        this.numbers  = [];
        this.duration = 4;
        this.isRender = false;
        this.options = {
            useEasing: true,
        }
    }   

    Init(){
        this.container = $(this.selectorContainer);
        if(this.container.length == 0){
            return;
        }
        this.FillData();
        this.CheckScroll();
        $(window).on('scroll', () => this.CheckScroll());
    }


    FillData(){
        this.container.find(".advantages-item").each((i, e) => {
            let item = $(e);
            let startVal = 0;
            let decimals = 0;
            let endVal = parseFloat(item.find("span").text().replace(',', '.'));
            
            if(item.hasClass('animate-number-up')){
                startVal = 0;
            }
            else{
                startVal = 100;
                decimals = 2;
            }
            item.find("span").text(startVal);
            this.numbers.push({
                item: item.find("span").get(0),
                startVal: startVal,
                endVal: endVal,
                decimals: decimals
            });
        });
    }

    StartAnimate(){
        this.numbers.forEach(e => {
            new CountUp(
                e.item,
                e.startVal,
                e.endVal,
                e.decimals,
                this.duration,
                this.options
            ).start();
        });
    }


    CheckScroll(){
        if(!this.isRender){
            let eventStart = $(".advantages-block").position().top - screen.height + $(".advantages-block").height();
            if($(window).scrollTop() > eventStart){
                this.StartAnimate();
                this.isRender = true;
            }
            
        }
    }
}


export default new IndexAdvantagesNumberAnimate(".index-page section.advantages-block");