import Slider from './Slider';


class HistorySlider extends Slider{
    constructor(selector){
        super(selector);
        this.selectorProgress = `${selector} .slider-progress .line-hover`;

        this.breakpoints = {
            default: {
                slides: 4
            },
            md: {
                width: 992,
                slides: 3
            },
            sm:{
                width: 768,
                slides: 2
            },
            xs:{
                width: 576,
                slides: 1
            }
        };
    }


    Init(){
        super.Init();
        if(this.targetSlider.length === 0){
            return;
        }
        this.progress = $(this.selectorProgress);
        this.CalcScroll();
    }


    CalcScroll(){
        if($(document).width() < this.breakpoints.xs.width){
            let fullScrollWidth = $(".history .slider-progress").width()
            let count = $(".history .history-item").length;
            let selectIndex = $('.history .slick-current').data('slick-index');
            let scrollWidthPercent = 1 / count;
            let scrollWidth = fullScrollWidth * scrollWidthPercent;
            let offsetWidth = scrollWidth * selectIndex;

            this.progress.css("margin-left", offsetWidth);
            this.progress.css("width", scrollWidth);
        }
        else{
            let visibleWidth = this.targetSlider.width();
            let fullWidth = this.targetSlider.find(".slick-track").width();
            let fullScrollWidth = $(".history .slider-progress").width();
            
    
            let offsetTrack = this.targetSlider.find(".slick-track").offset().left;
            let offsetVisible = this.targetSlider.offset().left;
            let offset = offsetVisible - offsetTrack;
    
            let precent = visibleWidth / fullWidth;
            let marginPercent = offset / fullWidth;
    
            if(precent + marginPercent > 1){
                marginPercent = 1 - precent;
            }
            else if(marginPercent < 0){
                marginPercent = 0;
            }
    
            let scrollWidth = precent * fullScrollWidth;
            let margin = marginPercent * fullScrollWidth;
    
            this.progress.css("margin-left", margin);
            this.progress.css("width", scrollWidth);
        }
        requestAnimationFrame(t => this.CalcScroll());
    }


    get settings(){
        return Object.assign(super.settings, {
            slidesToShow: this.breakpoints.default.slides,
            slidesToScroll: 1,
            prevArrow: null,
            nextArrow: null,
            swipeToSlide: true,
            responsive: [
                {
                    breakpoint: this.breakpoints.md.width,
                    settings:{
                        slidesToShow: this.breakpoints.md.slides 
                    }
                },
                {
                    breakpoint: this.breakpoints.sm.width,
                    settings:{
                        slidesToShow: this.breakpoints.sm.slides,
                    }
                },
                {
                    breakpoint: this.breakpoints.xs.width,
                    settings:{
                        slidesToShow: this.breakpoints.xs.slides,
                        variableWidth: true
                    }
                }
            ]
        });
    }
}


export default new HistorySlider("section.about-page .history");